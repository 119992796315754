<script setup lang="ts">
import StaticLayout from "@/components/static/StaticLayout.vue"

const props = defineProps<{
  loginUrl: string
  resetUrl: string
  registerUrl: string
}>()
</script>

<template>
  <StaticLayout>
    <template #overline>Welcome back!</template>
    <template #title>Member Login</template>
    <template #lead>
      <div class="max-w-sm">
        Drop your email and password below to enter the XYPN Portal.
      </div>
    </template>

    <form :action="loginUrl" method="POST" class="mb-6 space-y-5 max-w-sm">
      <!-- <Csrf />-->
      <div>
        <BaseInput
          type="email"
          label="Email"
          placeholder="Email"
          name="email"
          autocomplete="email"
          required
        />
      </div>

      <div>
        <BaseInput
          type="password"
          label="Password"
          placeholder="Password"
          name="password"
          required
        />
      </div>

      <button type="submit" class="w-full xy-btn">Log In</button>
    </form>

    <div class="mb-16 text-gray-700">
      Forgot your password?
      <a :href="resetUrl" class="xy-link">We've got you!</a>
    </div>

    <div class="mb-2">
      <h2
        class="text-2xl leading-6 font-normal font-display text-gray-800 space-y-2 sm:inline-flex sm:space-y-0"
      >
        <div class="sm:mr-4">Not an XYPN member?</div>
        <div>
          <a
            class="border-b-2 border-xy-green font-extrabold"
            :href="props.registerUrl"
            >Join Today &xrarr;
          </a>
        </div>
      </h2>
    </div>
  </StaticLayout>
</template>
